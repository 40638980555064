@import "variables";

.main-navigation {
  margin: 0 auto;
  .sans-serif-regular();
}

/* horizontal  */
ul.silo, ul.default {
  margin: 0 10px;
  padding: 0 0 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  li {
    position: relative;
    flex: 1 0 auto;
    margin: 0 0 0 5px;
    text-align: center;
    a {
      position: relative;
      display: block;
      padding: 0 10px;
      font-size: 13px;
      line-height: 33px;
      letter-spacing: 0.04em;
      text-decoration: none;
      color: @darkgrey;
      background-color: @lightgrey;
      &.featured {
        color: @pink;
      }
      sup {
        display: inline-block;
        margin: 0 0 0 -2px;
        padding: 0 3px 0 3px;
        background-color: @pink;
        font-size: 9px;
        line-height: 13px;
        color: @white;
      }
    }
    &:first-child span {
      position: relative;
      display: block;
      height: 33px;
      padding: 0 10px;
      font-size: 0;
      line-height: 33px;
      letter-spacing: 0.04em;
      color: @darkgrey;
      background-color: @lightgrey;
      &:before {
        display: block;
        height: 33px;
        font-family: @icon;
        font-size: 13px;
      }
    }
    &.no-children-sale {
      a {
        color: @pink;
      }
    }
  }
}
ul.default {
  display: flex;
  li:first-child {
    margin: 0;
    span:before {
      content: '\f107';
    }
  }
}
ul.silo {
  display: none;
}
.silo {
  ul.default {
    display: none;
  }
  ul.silo {
    display: flex;
    li:first-child {
      margin: 0;
      span:before {
        content: '\f106';
      }
    }
  }
}

.no-silo {
  ul.silo, ul.default {
    li:first-child {
      display: none;
    }
    li:nth-child(2){
      margin: 0;
    }
  }
}
@media screen and (min-width: 769px) {
  ul.silo, ul.default {
    margin: 0 20px;
  }
}
@media screen and (min-width: 1160px) {
  ul.silo, ul.default {
    max-width: 1160px;
    margin: 0 auto;
  }
}


/* common styles */
.tree {
  display: none;
  a {
    sup {
      display: inline-block;
      margin-top: -13px;
      margin-left: 3px;
      padding: 0 3px;
      background-color: @pink;
      color: @white;
      line-height: 12px;
    }
  }
}
.tree .children-gems {
  .top, .colors, .famous {
    a {
      .gem-icons();
    }
  }
}

.touchevents {
  /* nav container */
  ul.tree {
    display: block;
    position: fixed;
    top: 0;
    left: calc(-100% - 40px);
    z-index: 16;
    width: 100%;
    max-width: 400px;
    bottom: 0;
    overflow: hidden;
    margin: 0;
    box-sizing: border-box;
    background-color: @white;
    transition: all 0.3s ease-in-out;
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.5);
    line-height: 40px;
    a {
      display: block;
    }
  }
  .main-navigation {
    &.box-open {
      .tree {
        transition: all 0.3s ease-in-out;
        left: 0;
      }
    }
  }

  /* nav elements */
  ul.tree {
    li {
      margin: 0;
      font-size: 13px;
      line-height: 40px;
      border-top: 1px solid @mediumgrey;
      .nav-promo-block {
        display: none !important;
      }
      .mobile {
        display: inline-block;
      }
    }
    .close-menu, .close-sub {
      position: relative;
      padding-left: 40px;
      .sans-serif-bold();
      cursor: pointer;
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 40px;
        height: 40px;
        font-family: @icon;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
      }
    }

    .close-menu:before {
      content: '\e901';
    }

    .close-sub {
      display: none;

      &:before {
        content: '\f104';
      }
    }

    &.jewelry,
    &.gems,
    &.brands,
    &.lexicon,
    &.live {
      .close-sub {
        display: block;
        background-color: #f3f0eb;
      }
    }

    a {
      color: @darkgrey;
      text-decoration: none;
      .sans-serif-regular();
      &.toggle-link {
        display: block;

        &:after {
          display: inline-block;
          width: 40px;
          float: right;
          font-family: @icon;
          font-size: 15px;
          text-align: center;
          content: '\f105';
        }
      }
    }

    /* top-level links */
    & > li {
      &:last-child {
        border-bottom: 1px solid @mediumgrey;
      }
      > a {
        color: @darkgrey;
        text-decoration: none;

        i {
          display: inline-block;
          float: left;
          width: 40px;
          font-family: @icons;
          font-size: 18px;
          line-height: 40px;
          text-align: center;
          font-style: normal;
        }
      }

      &.no-children-gifts {
        i {
          font-family: @icon;
          font-size: 14px;
        }
      }
      &.no-children-sale {
        a {
          color: @pink;
        }
      }
    }

    /* 2nd level (tabs) */
    & > li {
      & > ul {
        position: absolute;
        top: 80px;
        left: 100%;
        bottom: 0;
        width: 100%;
        padding-top: 12px;
        overflow-x: auto;
        white-space: nowrap;
        background-color: #f3f0eb;
        font-size: 0;
        line-height: 0;
        display: flex;
        a {
          padding: 0 10px;
        }

        & > li {
          flex: 1 0 auto;
          text-align: center;
          display: inline-block;
          margin: 0 10px 0 0;
          border-top: none;
          .tab-link {
            display: block;
            padding: 0 10px;
            border: 1px solid @darkgrey;
            border-radius: 30px;
            background-color: #f3f0eb;
            font-size: 13px;
            line-height: 30px;
            cursor: pointer;
          }
          & > a {
            display: block;
            color: @pink;
          }
          &:first-child {
            margin: 0 10px;
          }
          &.all {
            display: none;
          }
          & > ul {
            position: fixed;
            top: 140px;
            left: 0;
            bottom: 0;
            width: 100%;
            max-width: 400px;
            overflow-y: auto;
            background-color: #f3f0eb;
            display: none;
            text-align: left;
            a {
              padding-left: 20px;
            }
          }
        }
      }

      & > div {
        position: absolute;
        top: 83px;
        left: 100%;
        bottom: 0;
        width: 100%;
        background-color: @white;
        overflow-y: auto;

        a {
          padding: 0 10px;
        }
      }
    }

    /* show / hide */
    &.jewelry > li.children-jewelry,
    &.gems > li.children-gems,
    &.brands > li.children-brands,
    &.lexicon > li.children-lexicon,
    &.live > li.children-live {
      border-top-color: @lightgrey;
      & > ul, & > div {
        left: 0;
      }
    }


    /* active tab links */

    &.jewelry > li.children-jewelry.types > ul .types,
    &.jewelry > li.children-jewelry.designs > ul .designs,
    &.jewelry > li.children-jewelry.metals > ul .metals,
    &.jewelry > li.children-jewelry.sizes > ul .sizes,
    &.gems > li.children-gems.top > ul .top,
    &.gems > li.children-gems.famous > ul .famous,
    &.gems > li.children-gems.colors > ul .colors,
    &.brands > li.children-brands > div,
    &.lexicon > li.children-lexicon.gems > ul .gems,
    &.lexicon > li.children-lexicon.general > ul .general,
    &.lexicon > li.children-lexicon.interesting > ul .interesting,
    &.lexicon > li.children-lexicon.counselor > ul .counselor,
    &.live > li.children-live.auctions > ul .auctions,
    &.live > li.children-live.about > ul .about {
      span {
        position: relative;
        z-index: 7;
        border-color: @pink;
        color: @pink;
      } // tab version
      ul {
        display: block;
        li.all a {
          color: @pink;
          &:before {
            display: none;
          }
          &:after {
            content: ' >';
          }
        }
      }
    }

    &.lexicon > li.children-lexicon.gems > ul .gems ul li {
      display: list-item !important;
      &.more {
        display: none !important;
      }
    }
  }
}

/* old nav */
.touchevents {
  .old-nav {

    ul.tree {
      & > li { // top-level
        & > ul {
          & > li { // tab-links
            border-top: 1px solid @mediumgrey;
            & > .tab-link {
              background-color: #f3f0eb;
              .sans-serif-light();
              color: @darkgrey;
              &:after {
                float: right;
                font-family: @icon;
                content: '\f107';
              }
            }
            & > a {
              padding: 0 10px 0 40px;
              color: @pink;
              &:after {
                content: ' >';
              }
            }
            & > ul {
              & > li { // links
                padding: 0 10px 0 40px;
                background-color: @lightgrey;
              }
            }
          }
        }
        & > div { // collections
          & > a {
            padding-left: 40px;
            border-top: 1px solid @mediumgrey;
            background-color: @lightgrey;
            color: @pink;
            &:after {
              content: ' >';
            }
          }
          & > ul {
            & > li { // links
              padding: 0 10px 0 40px;
              background-color: @lightgrey;
            }
          }
        }
      }
    }

    ul.tree {
      overflow-y: auto;
      top: 77px;
      .close-menu, .close-sub {
        display: none;
      }
      & > li {
        max-height: 40px;
        overflow: hidden;
      }

      a {
        color: @darkgrey;
        text-decoration: none;
        .sans-serif-regular();
        &.toggle-link {
          display: block;
          &:after {
            display: inline-block;
            width: 40px;
            float: right;
            font-family: @icon;
            font-size: 15px;
            text-align: center;
            content: '\f107';
          }
        }
      }

      /* show / hide */
      &.jewelry > li.children-jewelry,
      &.gems > li.children-gems,
      &.brands > li.children-brands,
      &.lexicon > li.children-lexicon,
      &.live > li.children-live {
        & > a:after {
          content: '\f106';
        }
      }

      /* 2nd level (tabs) */
      & > li {
        & > ul {
          position: relative;
          top: 0;
          left: 0;
          padding-top: 0;
          overflow-x: hidden;
          white-space: normal;
          font-size: 0;
          line-height: 0;
          display: block;
          a {

          }

          & > li {
            text-align: left;
            display: block;
            margin: 0;
            .tab-link {
              display: block;
              border: none;
              border-radius: 0;
              line-height: 40px;
              padding-left: 40px;
            }


            & > ul {
              position: relative;
              top: 0;
              overflow-y: hidden;
              display: block;
              max-height: 0;
              background-color: @lightgrey;
              li.all {
                display: none;
              }
            }
          }
        }

        & > div {
          position: relative;
          top: 0;
          left: 0;
          overflow-y: hidden;
        }
      }


      /* show / hide */
      &.jewelry > li.children-jewelry,
      &.gems > li.children-gems,
      &.brands > li.children-brands,
      &.lexicon > li.children-lexicon,
      &.live > li.children-live {
        border-top-color: @mediumgrey;
        max-height: 4500px;
      }

      /* active tab links */
      &.jewelry > li.children-jewelry.types > ul .types,
      &.jewelry > li.children-jewelry.designs > ul .designs,
      &.jewelry > li.children-jewelry.metals > ul .metals,
      &.jewelry > li.children-jewelry.sizes > ul .sizes,
      &.gems > li.children-gems.top > ul .top,
      &.gems > li.children-gems.famous > ul .famous,
      &.gems > li.children-gems.colors > ul .colors,
      &.brands > li.children-brands > div,
      &.lexicon > li.children-lexicon.gems > ul .gems,
      &.lexicon > li.children-lexicon.general > ul .general,
      &.lexicon > li.children-lexicon.interesting > ul .interesting,
      &.lexicon > li.children-lexicon.counselor > ul .counselor,
      &.live > li.children-live.auctions > ul .auctions,
      &.live > li.children-live.about > ul .about {
        span:after {
          content: '\f106';
        } // tab version
        ul {
          max-height: 4500px;
        }
      }

      &.lexicon > li.children-lexicon.gems > ul .gems ul li {
        display: list-item !important;
        &.more {
          display: none !important;
        }
      }
    }
  }
  .header-banderole-visible {
    .old-nav ul.tree {
      top: 107px;
    }
  }
}
@media screen and (min-width: 768px) and (pointer: coarse){
  .touchevents {
    .old-nav {
      ul.tree {
        top: 93px;
      }
    }
    .header-banderole-visible {
      .old-nav ul.tree {
        top: 123px;
      }
    }
  }
}
@media screen and (min-width: 1024px) and (pointer: coarse){
  .touchevents {
    .old-nav {
      ul.tree {
        top: 98px;
      }
    }
    .header-banderole-visible {
      .old-nav ul.tree {
        top: 128px;
      }
    }
  }
}
@media screen and (min-width: 1280px) and (pointer: coarse){
  .touchevents {
    .old-nav {
      ul.tree {
        top: 130px;
      }
    }
    .header-banderole-visible {
      .old-nav ul.tree {
        top: 160px;
      }
    }
  }
}

/* dropdown style */
@media screen and (min-width: 1024px) {
  .no-touchevents {
    .main-navigation {
      position: relative;
      z-index: 3;
      padding: 0 calc((100% - 1200px)/2);
      border-bottom: 1px solid @white;
      box-shadow: 0px 5px 10px -5px @mediumgrey;
      ul.silo, ul.default {
        display: none;
      }
      li {
        margin: 0;
      }
    }
    ul.tree {
      position: initial;
      margin: 0 auto;
      padding: 0 20px;
      text-align: center;
      display: flex;
      .tab-link {
        display: block;
        .sans-serif-bold();
        color: @pink;
        padding: 10px 0;
      }
      .famous .tab-link {
        margin-top: 20px;
      }
      .close-menu, .close-sub {
        display: none;
      }
      > li {
        flex: 1 0 auto;
        display: inline-block;
        font-size: 13px;
        line-height: 33px;
        a {
          position: relative;
          color: @darkgrey;
          text-decoration: none;
          &:hover {
            color: @pink;
          }
        }
        & > a {
          position: relative;
          display: block;
          &:hover {
            &:after {
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              display: block;
              height: 5px;
              background-color: @pink;
              content: '';
            }
          }
        }
        ul {
          li {
            & > .toggle-link {
              .sans-serif-bold();
              color: @pink;
            }
          }
        }
        li {
          line-height: 30px;
          a {
            &:hover {
              text-decoration: underline;
            }
          }
          &.more a {
            color: @pink;
          }
        }
        i {
          display: none;
        }

        & > ul {
          box-sizing: border-box;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          max-height: 0;
          overflow: hidden;
          text-align: left;
          position: absolute;
          left: 0;
          z-index: 5;
          width: 100%;
          padding: 0 calc((100% - 1200px)/2);
          background-color: @white;
          transition: all .1s ease-in-out;
          transition-delay: 0s;
          & > li {
            padding: 0 20px;
            &.nav-promo-block {
              padding: 20px 0;
              a {
                display: block;
              }
            }
          }
        }
        &:hover > ul {
          max-height: 2500px;
          padding: 20px calc((100% - 1200px)/2) 30px;
          border-top: 1px solid @pink;
          border-bottom: 1px solid @pink;
          transition: all .3s ease-in-out;
          transition-delay: .5s;
        }
        &.children-lexicon,
        &.children-jewelry {
          & > ul {
            li:first-child {
              grid-column-start: 1;
              grid-column-end: 2;
            }
            li:nth-child(2){
              grid-column-start: 2;
              grid-column-end: 3;
            }
            li:nth-child(3){
              grid-column-start: 3;
              grid-column-end: 4;
            }
            li:nth-child(4){
              grid-column-start: 4;
              grid-column-end: 5;
            }
            li:nth-child(5){
              grid-column-start: 5;
              grid-column-end: 6;
            }
          }
        }
        &.children-gems {
          &:hover {
            & > ul {
              display: grid;
            }
          }
          & > ul {
            grid-template-columns: 1fr;
            & > li:first-child {
              grid-row-start: 1;
              grid-row-end: 2;
              grid-column-start: 1;
              grid-column-end: 2;
              ul {
                display: flex;
                flex-wrap: wrap;
                li {
                  flex: 0 0 20%;
                  &.all {
                    display: none;
                  }
                }
              }
            }
            & > li:nth-child(2){
              grid-row-start: 2;
              grid-row-end: 3;
              grid-column-start: 1;
              grid-column-end: 2;
              ul {
                display: flex;
                flex-wrap: wrap;
                li {
                  flex: 0 0 20%;
                  &:nth-child(2){
                    flex: 0 0 20%;
                  }
                  &:nth-child(3){
                    flex: 0 0 20%;
                  }
                  &:nth-child(4){
                    flex: 0 0 60%;
                  }
                  &.all {
                    display: none;
                  }
                }
              }
            }
            & > li:nth-child(3) {
              grid-row-start: 3;
              grid-row-end: 4;
              grid-column-start: 1;
              grid-column-end: 2;
              ul {
                display: flex;
                flex-wrap: wrap;
                li {
                  flex: 0 0 20%;
                  &.all {
                    display: none;
                  }
                }
              }
            }
            & > li:nth-child(4){
              grid-row-start: 4;
              grid-row-end: 5;
              grid-column-start: 1;
              grid-column-end: 2;
            }
          }
        }
        &.children-brands {
          & > div {
            position: absolute;
            left: 0;
            z-index: 5;
            box-sizing: border-box;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 2fr;
            max-height: 0;
            overflow: hidden;
            text-align: left;
            padding: 0 calc((100% - 1200px)/2);
            background-color: @white;
            transition: all .1s ease-in-out;
            transition-delay: 0s;
            li {
              padding: 0 20px;
            }
            > a {
              grid-row-start: 1;
              grid-row-end: 2;
              grid-column-start: 1;
              grid-column-end: 3;
              padding: 0 20px;
              .sans-serif-bold();
              color: @pink;
            }
            ul:nth-child(2) {
              grid-row-start: 2;
              grid-row-end: 3;
              grid-column-start: 1;
              grid-column-end: 2;
            }
            ul:nth-child(3) {
              grid-row-start: 2;
              grid-row-end: 3;
              grid-column-start: 2;
              grid-column-end: 3;
            }
            ul:nth-child(4) {
              grid-row-start: 2;
              grid-row-end: 3;
              grid-column-start: 3;
              grid-column-end: 4;
            }
            .nav-promo-block {
              grid-row-start: 2;
              grid-row-end: 3;
              grid-column-start: 4;
              grid-column-end: 5;
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-column-gap: 20px;
              grid-template-rows: 1fr 1fr;
              grid-row-gap: 20px;
            }
          }
          &:hover > div {
            max-height: 1000px;
            padding: 20px calc((100% - 1200px)/2);
            border-top: 1px solid @pink;
            border-bottom: 1px solid @pink;
            transition: all .3s ease-in-out;
            transition-delay: .5s;
          }
        }
        &.children-live {
          & > ul {
            grid-template-columns: 3fr 1fr 1fr;
            li.auctions {
              grid-row-start: 1;
              grid-row-end: 2;
              grid-column-start: 2;
              grid-column-end: 3;
            }
            li.about {
              grid-row-start: 1;
              grid-row-end: 2;
              grid-column-start: 3;
              grid-column-end: 4;
            }
            li.nav-promo-block {
              grid-row-start: 1;
              grid-row-end: 2;
              grid-column-start: 1;
              grid-column-end: 2;
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-column-gap: 20px;
              padding: 20px;
            }
          }
        }
        &.no-children-app {
          display: none;
        }
        &.no-children-sale {
          a {
            color: @pink;
          }
        }
      }
    }
    .tree .children-gems {
      .top, .colors, .famous {
        a:before {
          margin-top: 0;
        }
      }
      .all {
        a {
          display: block;
          margin-top: 10px;
          color: @pink;
          .sans-serif-bold();
          &:after {
            content: ' >';
          }
        }
      }
    }
  }
  ul.default, ul.silo {
    opacity: 0;
  }
  .touchevents {
    ul.default, ul.silo {
      opacity: 1;
    }
  }

  // spanish live
  .no-touchevents .es ul.tree > li.children-live {
    & > ul {
      grid-template-columns: 1fr 1fr 3fr;
      li.auctions {
        grid-column-start: 1;
        grid-column-end: 2;
      }
      li.about {
        grid-column-start: 2;
        grid-column-end: 3;
      }
      li.nav-promo-block {
        grid-column-start: 3;
        grid-column-end: 4;
      }
    }
  }

}
@media screen and (min-width: 1200px) {
  .no-touchevents {
    ul.tree {
      > li {
        & > a {
          padding: 7px 0 5px;
        }
        sup {
          top: 7px;
        }
      }
    }
  }
}

/* breadcrumbs */
#breadcrumbs {
  display: none;
}
@media screen and (min-width: 1024px) and (hover: hover){
  .no-touchevents {
    #breadcrumbs {
      display: block;
      padding: 15px 0;
      font-size: 11px;
      line-height: 20px;
      width: 100%;
      clear: both;
      a, strong {
        display: block;
        float: left;
        &:after,
        &:before {
          display: inline;
          .sans-serif-regular();
        }
        &.back{
          &:after {
            display: inline;
            margin: 0 5px;
            content: '|';
            float: right;
          }
          &:before {
            margin: 0 5px 0  10px;
            content: '<';
            float: left;
          }
        }
        &.link {
          margin-right: 10px;
          &:after {
            content: '>';
            color: @darkgrey;
            float: right;
            padding-left: 5px;
          }
        }
      }
    }
  }
}
