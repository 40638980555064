/* style header elements except navigation */

#site-header {
  width: 100%;
  background-color: @white;
  transition: top 1s;
  .header-top {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 20px;
    background: #b4146e;
    overflow: hidden;
  }
  .header-main {
    position: relative;
    height: 102px;
    font-size: 0;
    line-height: 0;
  }
  #logo {
    position: absolute;
    top: 12px;
    left: 96px;
    right: 96px;
    display: block;
    height: 42px;
    margin: 0;
    text-align: center;
    img {
      display: inline-block;
      width: auto;
      margin: 3px 0;
      height: 26px;
    }
  }
  /* trigger-links */
  .trigger-link {
    &-menu {
      left: 4px;
    }
    &-account {
      left: 45px;
    }
    &-wishlist {
      right: 45px;
    }
    &-cart {
      right: 0;
    }
  }
}
/* top row */
.header-top {
  letter-spacing: 0;
  a {
    color: @white;
  }
  p {
    .sans-serif-regular();
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.05em;
    color: @white;
    strong {
      .sans-serif-bold();
    }
  }
  .top-info {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 20px;
    margin: 0;
    overflow: hidden;
    transition: opacity 0.5s;
    &.usp-1 {
      span {
        padding: 0 7px 0 0;
      }
    }
    &.service {
      width: 100%;
      box-sizing: border-box;
      a {
        line-height: 10px;
        padding: 5px 0;
      }
    }
    &.trust {
      left: auto;
      right: 10px;
      width: auto;
      font-size: 0;
      line-height: 0;
      .trust-icon {
        img {
          height: 22px;
          margin: 2px 0;
          float: right;
        }
      }
      .trust-badge {
        display: none;
      }
    }
    &.shipping {
      padding: 0 0 0 10px;
      .shipping {
        float: left;
        .freeshipping {
          &:before {
            display: inline;
            vertical-align: middle;
            font-family: @icon;
            content: '\f00c';
          }
        }
      }
    }
  }
  &.show-1 {
    .usp-1, .trust {
      opacity: 1;
    }
    .usp-2,
    .shipping,
    .service,
    .new-customer {
      opacity: 0;
    }
  }
  &.show-2 {
    .usp-2 {
      opacity: 1;
    }
    .usp-1, .trust,
    .shipping,
    .service,
    .new-customer {
      opacity: 0;
    }
  }
  &.show-3 {
    .shipping {
      opacity: 1;
    }
    .usp-1, .trust,
    .usp-2,
    .service,
    .new-customer {
      opacity: 0;
    }
  }
  &.show-4 {
    .service {
      opacity: 1;
    }
    .usp-1, .trust,
    .usp-2,
    .shipping,
    .new-customer {
      opacity: 0;
    }
  }
  &.show-5 {
    .new-customer {
      opacity: 1;
    }
    .usp-1, .trust,
    .usp-2, .service,
    .shipping {
      opacity: 0;
    }
  }
}

/* search form */
#search-header, #algolia-search {
  z-index: 9;
  position: absolute;
  top: 52px;
  right: 0;
  left: 0;
  display: block;
  height: 40px;
  background: @white;
}


#search-header, #algolia-search {
  .search-mini {
    display: block;
    margin: 5px 10px;
    .input-box {
      margin: 0;
      input#search {
        height: 35px;
        margin: 0;
        padding: 3px 10px;
        border-color: @juwelogold;
        border-radius: 1px;
        .sans-serif-light();
        font-size: 12px;
        letter-spacing: 0.05em;
      }
      button {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 15;
        width: 35px;
        height: 35px;
        padding: 0;
        border: none;
        background: none;
        font-size: 0;
        cursor: pointer;
        .no-shadow();
        &:after {
          position: absolute;
          top: -2px;
          left: 0;
          display: block;
          width: 40px;
          height: 40px;
          background-image: url(../images/trigger-link-icons-grey.png);
          background-size: 100%;
          background-position: 0px -400%;
          content: ' ';
        }
      }
      .clear-search-input {
        position: absolute;
        top: 0;
        right: 45px;
        z-index: 15;
        display: none;
        height: 35px;
        padding: 0;
        border: none;
        cursor: pointer;
        &:after {
          content: '\f00d';
          font-family: @icon;
          font-size: 15px;
          line-height: 35px;
          padding-left: 10px;
        }
      }
    }

    /* search suggests */
    .search-autocomplete {
      position: fixed;
      top: 110px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      overflow: auto;
      box-sizing: border-box;
      padding: 10px;
      background: @white;
      border: 1px solid #949494;
      border-top: none;
      z-index: 7;
      li {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 8px 0 7px;
        border-bottom: 1px solid #dedede;
        float: left;
        font-size: 12px;
        line-height: 15px;
        cursor: pointer;
        mark {
          border-bottom: 1px solid @pink;
          color: @pink;
          background-color: transparent;
        }
        &.productName {
          display: table-row;
          padding: 5px 0 5px 8px;
          .search-suggest-image {
            width: 70px;
            height: 70px;
          }
          .search-suggest-label {
            display: table-cell;
            padding-left: 20px;
            vertical-align: middle;
          }
        }
      }
      ul {
        width: 100%;
        float: left;
        margin: 0 0 20px;
        &.search-categories {
          li {
            font-size: 15px;
            line-height: 25px;
            &:last-child {
              font-size: 13px;
              line-height: 20px;
              border-bottom: none;
              margin-top: 10px;
            }
          }
        }
        &.search-products {
          display: table;
        }
      }
      .clear-search-input {
        display: block;
        padding: 10px 0 0;
        border-top: 1px solid #dedede;
        .sans-serif-regular();
        font-size: 12px;
        line-height: 15px;
        cursor: pointer;
        &:before {
          font-family: @icon;
          content: '\f00d';
          padding-right: 5px;
        }
      }
    }
  }

  .search-autocomplete {
    h4 {
      display: block;
      width: 100%;
      margin: 20px 0;
      padding: 0 0 5px;
      border-bottom: 1px solid @darkgrey;
      .sans-serif-bold();
      font-size: 13px;
      line-height: 15px;
      text-transform: none;
    }
    li {
      .search-suggest-label {
        .sans-serif-regular();
      }
      .search-suggest-image {
        float: left;
        width: 85px;
        margin: 0 10px 0 0;
      }
    }
  }
}

.no-touchevents #search-header .search-autocomplete li:hover,
#search-header .search-autocomplete li.selected {
  cursor: pointer;
  background-color: @pink;
  color: @white;
  padding-left: 8px !important;
  mark {
    color: @white;
    border-bottom: 1px solid @white;
  }
}

/* header boxes */
.header-content-box {
  position: absolute;
  top: 67px;
  left: 10px;
  right: 0;
  z-index: 11;
  box-sizing: border-box;
  background: #f6f6f6;
  padding: 0 10px;
  clear: both;
  display: none;
  overflow: auto;
  &.box-open {
    display: block;
    .darkshadow();
    z-index: 10;
  }
  .block {
    background: none;
    box-shadow: none;
    padding: 10px 0;
    margin: 0;
  }
  .header-content-box-close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    display: block;
    width: 30px;
    height: 30px;
    background-color: @pink;
    text-align: center;
    line-height: 32px;
    color: @white;
    cursor: pointer;
  }
}

.trigger-link {
  position: absolute;
  top: 5px;
  display: block;
  width: 42px;
  height: 42px;
  box-sizing: border-box;
  border-style: solid;
  border-color: transparent;
  border-bottom-width: 0;
  font-size: 0;
  text-align: center;
  background-color: @white;
  cursor: pointer;
  color: @darkgrey;
  
  &:hover {
    text-decoration: none;
  }
  
  &.link-active {
    background: #f6f6f6;
    .darkshadow();
  }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 42px;
    height: 42px;
    background-image: url('../images/trigger-link-icons-grey.png');
    background-position-x: 0;
    background-size: 100%;
    content: ' ';
  }
  &-menu {
    &.link-active {
      background: @white;
      .no-shadow();
    }
    &:before {
      display: none;
      background-position-y: -300%;
    }
    span {
      display: block;
      position: absolute;
      height: 1px;
      width: 24px;
      background: #7e7e7d;
      border-radius: 1px;
      opacity: 1;
      left: 10px;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      &:nth-child(1){
        top: 10px;
      }
      &:nth-child(2),
      &:nth-child(3){
        top: 16px;
      }
      &:nth-child(4){
        top: 22px;
      }
    }
    .text {
      position: absolute;
      display: block;
      left: 9px;
      top: 13px;
      .sans-serif-bold();
      font-size: 8px;
      line-height: 40px;
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 0.12em;
    }
    &.link-active {
      span {
        &:nth-child(1){
          top: 19px;
          width: 0%;
          left: 50%;
        }
        &:nth-child(2){
          transform: rotate(45deg);
        }
        &:nth-child(3){
          transform: rotate(-45deg);
        }
        &:nth-child(4){
          top: 19px;
          width: 0%;
          left: 50%;
        }
      }
    }
  }
  &-cart {
    &:before {
      background-position-y: 0;
    }
    .cart-amount {
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 2;
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      background-color: @pink;
      .sans-serif-bold();
      font-size: 9px;
      line-height: 17px;
      text-align: center;
      color: @white;
    }
  }
  &-wishlist {
    &:before {
      background-position-y: -100%;
    }
    .wishlist-amount {
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 2;
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      background-color: @pink;
      .sans-serif-bold();
      font-size: 9px;
      line-height: 17px;
      text-align: center;
      color: @white;
    }
  }
  &-account {
    &:before {
      background-position-y: -200%;
    }
    .logged-in-marker {
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 2;
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      background-color: @pink;
      &:before {
        display: block;
        font-family: 'FontAwesome';
        font-size: 9px;
        line-height: 15px;
        text-align: center;
        color: @white;
        content: '\f00c';
      }
    }
  }
}


.main-container {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  margin: 123px 0 0;
}
.header-b {
  #site-header {
    position: relative;
    top: 0 !important;
    box-shadow: none !important;
  }
  .main-container {
    margin: 0;
    padding: 10px 0 0;
  }
}

#main-navigation {
  .main-nav-sub-categories {
    top: 76px;
  }
}
.header-banderole-visible {
  #main-navigation {
    .main-nav-sub-categories {
      top: 106px;
    }
  }
}

.app-banner-visible {
  #site-header {
    margin-top: 80px;
  }
  #main-navigation .main-nav-sub-categories {
    top: 157px;
  }
}

@media screen and (min-width: 360px) {
  #site-header {
    #logo {
      img {
        height: 32px;
        margin: 0;
      }
    }
  }
}
@media screen and (min-width: 640px) {
  #site-header {
    .header-main {
      .flex-rows();
    }
    .trigger-link {
      position: relative;
      top: 11px;
      left: auto;
      right: auto;
      width: auto;
      .flex-item-grow();
      .sans-serif-light();
      font-size: 12px;
      line-height: 40px;
      letter-spacing: 0.04em;
      padding: 0 10px 0 0;
      &:before {
        position: relative;
        float: left;
      }
      &-menu {
        width: 40px;
        margin: 0 40px 0 10px;
        padding: 0;
      }
    }
    #logo {
      position: relative;
      top: 15px;
      left: auto;
      right: auto;
      width: auto;
      .flex-item-expand();
      margin: 0 5px;
      text-align: left;
    }
    .header-content-box {
      top: 72px;
      left: auto;
      width: 580px;
    }
  }
}
@media screen and (min-width: 768px) {
  #site-header {
    height: 142px;
    .header-main {
      height: 112px;
    }
    .trigger-link {
      top: 14px;
      &.link-active {
        height: 58px;
      }
    }
    #logo {
      img {
        height: 42px;
        margin: 0;
      }
    }
    #search-header, #algolia-search {
      top: 68px;
      .search-mini {
        .search-autocomplete {
          top: 126px;
        }
      }
    }
    .header-content-box {
      top: 86px;
    }
  }
  #main-navigation {
    .main-nav-sub-categories {
      top: 93px;
    }
  }
  .main-container {
    margin: 142px 0 0;
  }
  .header-banderole-visible {
    #main-navigation {
      .main-nav-sub-categories {
        top: 123px;
      }
    }
  }
  .app-banner-visible {
    #main-navigation .main-nav-sub-categories {
      top: 173px;
    }
    .main-container {
      margin: 222px 0 0;
    }
  }
}
@media screen and (min-width: 769px) {
  .trigger-link {
    margin: 0 10px;
  }
  #search-header, #algolia-search {
    padding: 0 10px;
    .search-mini {
      .search-autocomplete {
        top: 34px;
        left: 20px;
        right: 20px;
      }
    }
  }
  .header-content-box {
    right: 10px;
  }
}
@media screen and (min-width: 1024px) {
  #site-header {
    position: relative;
    height: 98px;
    background-color: transparent;
    top: 0 !important;
    .no-shadow();
    .header-main {
      height: 78px;
      background-color: @white;
    }
    .trigger-link {
      top: 12px;
      height: 58px;
      padding: 0 10px;
      margin: 0 10px;
      line-height: 12px;
      text-align: center;
      &:before {
        float: none;
        margin: 0 auto -5px;
      }
      &-menu {
        top: 20px;
        margin: 0 20px 0 10px;
      }
      &-cart {
        .cart-amount {
          left: 50%;
        }
      }
      &-wishlist {
        .wishlist-amount {
          left: 50%;
        }
      }
      &-account {
        .logged-in-marker {
          left: 50%;
        }
      }
    }
    #logo {
      top: 19px;
      margin: 0 20px 0 10px;
      .flex-item-grow();
    }
    #search-header, #algolia-search {
      position: relative;
      top: 20px;
      right: auto;
      left: auto;
      .flex-item-expand();
      margin: 0 30px 0 40px;
      .search-mini {
        .search-autocomplete {
          position: absolute;
          top: 34px;
          left: 20px;
          right: 20px;
          bottom: auto;
          .clear-search-input {
            display: none !important;
          }
        }
      }
    }
    .header-content-box {
      top: 90px;
    }
  }
  #main-navigation {
    .main-nav-categories {
      position: relative !important;
      top: 0 !important;
      left: 0;
      right: 0;
      z-index: 1;
      display: block;
      box-sizing: border-box;
      height: 35px;
      background-color: @white;
      border-bottom: 1px solid @juwelogold;
      text-align: center;
      transition: top 1s;
      font-size: 0;
      line-height: 0;
    }
  }
  .main-container {
    margin: 0;
    padding: 20px 0 0;
  }

  .no-touchevents {
    #site-header {
      .trigger-link-menu {
        display: none;
      }
    }
    #logo {
      margin: 0 20px;
    }
  }
  .app-banner-visible {
    #main-navigation .main-nav-sub-categories {
      top: 171px;
    }
    .main-container {
      margin: 0;
    }
  }
}

@media screen and (min-width: 1200px) {
  #site-header {
    .header-content-box {
      top: -8px;
    }
  }
}

@media screen and (min-width: 1280px) {
  #site-header {
    height: 130px;
    background-color: transparent;
    .header-top {
      height: 28px;
      p {
        font-size: 12px;
        line-height: 28px;
      }
      .top-info {
        height: 28px;
        &.trust {
          right: 20px;
          .trust-icon {
            img {
              height: 26px;
            }
          }
        }
      }
    }
    .header-main {
      height: 102px;
      background-color: @white;
    }
    #logo {
      top: 28px;
      margin: 0 40px;
      img {
        height: 52px;
      }
    }
    .trigger-link {
      top: 28px;
      &-menu {
        top: 36px;
      }
    }
    #search-header, #algolia-search {
      position: relative;
      top: 36px;
      right: auto;
      left: auto;
      margin: 0 60px 0 50px;
      .flex-item-expand();
      .search-mini {
        display: block;
        margin: 0;
        .input-box {
          input#search {
            height: 40px;
            padding: 3px 10px;
          }
          button {
            width: 40px;
            height: 40px;
            &:after {
              top: 0;
              background-position: 1px -400%;
            }
          }
        }

        /* search suggests */
        .search-autocomplete {
          top: 39px;
          left: 10px;
          right: 10px;
        }
      }
    }
    .header-content-box {
      top: -26px;
    }
  }
  #main-navigation {
    .main-nav-categories {
      height: 45px;
    }
    .main-nav-sub-categories {
      top: 174px;
    }
  }
  .header-banderole-visible {
    #main-navigation {
      .main-nav-sub-categories {
        top: 204px;
      }
    }
  }
  .app-banner-visible {
    #main-navigation {
      .main-nav-categories {
        top: 220px;
      }
      .main-nav-sub-categories {
        top: 213px;
      }
    }
  }
  .main-container {
    margin: 0;
  }

  .no-touchevents {
    #site-header {
      #logo {
        margin: 0 40px 0 20px;
      }
    }
  }
}



// stick off in checkout
.checkout-onepage-index.page-scrolled {
  #site-header {
    position: relative;
    top: 0;
  }
  .main-container {
    margin: 0 auto;
  }
  #main-navigation {
    .main-nav-categories {
      position: relative;
      top: 0;
    }
  }
  &.show-header {
    #site-header {
      position: relative;
      top: 0;
    }
    .main-container {
      margin: 0 auto;
    }
    #main-navigation {
      .main-nav-categories {
        position: relative;
        top: 0;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .checkout-onepage-index {
    #site-header {
      .header-content-box {
        top: 90px;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .checkout-onepage-index {
    #site-header {
      .header-content-box {
        top: 15px;
      }
    }
  }
}
@media screen and (min-width: 1280px) {
  .checkout-onepage-index {
    #site-header {
      .header-content-box {
        top: 31px;
      }
    }
  }
}


/* from basic */


// header-top animation
.de {
  #site-header {
    .header-top {
      .service {
        .hotline {
          padding-right: 5px;
        }
        .mail {
          padding: 5px 0;
          line-height: 11px;
          &:before {
            padding-right: 5px;
            content: '| ';
          }
        }
      }
      &.show-1,
      &.show-2,
      &.show-3,
      &.show-4 {
        .trust {
          top: 0;
          opacity: 1;
          #tsTrustbadge {
            #tsCustomBadge4_db8d3657bdbe440c985ae127463eaad4 {
              margin: 0;
              width: 24px !important;
              height: 24px !important;
            }
          }
        }
      }
    }
  }
}
.en {
  #site-header {
    .header-top {
      .service {
        .hotline {
          padding-right: 5px;
        }
        .mail {
          padding: 5px 0;
          line-height: 11px;
          &:before {
            padding-right: 5px;
            content: '| ';
          }
        }
      }
      &.show-1,
      &.show-2,
      &.show-3,
      &.show-4 {
        .trust {
          opacity: 1;
        }
      }
    }
  }
}
.es {
  #site-header {
    .header-top {
      .service {
        .hotline {
          padding-right: 5px;
        }
        .mail {
          padding: 5px 0;
          line-height: 11px;
          &:before {
            padding-right: 5px;
            content: '| ';
          }
        }
      }
      &.show-1,
      &.show-4 {
        .trust {
          opacity: 1;
        }
      }
      &.show-2 {
        .trust {
          opacity: 0;
        }
      }
      &.show-3 {
        .trust {
          opacity: 0;
        }
      }
    }
  }
}
.fr {
  #site-header {
    .header-top {
      .service {
        .hotline {
          padding-right: 5px;
        }
        .mail {
          padding: 5px 0;
          line-height: 11px;
          height: 11px;
          &:before {
            padding-right: 5px;
            content: '| ';
          }
        }
      }
      &.show-1,
      &.show-3 {
        .trust {
          opacity: 0;
        }
      }
      &.show-2,
      &.show-4 {
        .trust {
          opacity: 1;
        }
      }
    }
  }
}
.nl {
  #site-header {
    .header-top {
      .service {
        .hotline {
          padding-right: 10px;
        }
        .mail {
          padding: 5px 0 5px 10px;
          line-height: 11px;
          &:before {
            padding-right: 5px;
            content: '| ';
          }
        }
      }
      &.show-1,
      &.show-2 {
        .trust {
          opacity: 1;
        }
      }
      &.show-3 {
        .trust {
          opacity: 0;
        }
      }
      &.show-4 {
        .trust {
          opacity: 0;
        }
      }
    }
  }
}
.it {
  #site-header {
    .header-top {
      &.show-1,
      &.show-2 {
        .trust {
          opacity: 1;
        }
      }
      &.show-3 {
        .trust {
          opacity: 0;
        }
      }
      &.show-4 {
        .trust {
          opacity: 0;
        }
      }
    }
  }
}

/* cart / wishlist */
.header-content-box-cart {
  .block-cart {
    .block-content {
      .actions {
        border-bottom: 1px solid #ececec;
        margin-bottom: 10px;
        padding: 0 0 10px;
        .btn {
          padding: 12px 20px 10px;
        }
      }
    }
    .totals {
      width: 100%;
      clear: both;
      padding: 0 0 5px;
      text-align: right;
    }
  }
}


/* login / account */
.header-content-box-account {
  .block-login {
    .input-box {
      margin: 15px 0 0;
      button {
        width: 100%;
      }
    }
  }
}

/* medium mobile */
@media screen and (min-width: 401px) {
  #site-header {

    /* top row */
    .header-top {
      &.show-1,
      &.show-2,
      &.show-3,
      &.show-4 {
        .top-info {
          &.trust {
            opacity: 1;
          }
        }
      }
    }

  }
}

/* large mobile */
@media screen and (min-width: 769px) {
  #site-header {

    /* header boxes */
    .header-content-box {
      padding: 0 20px 10px;
      .header-content-box-close {
        right: 20px;
      }
    }
  }
}


// header empty
#site-header.header-empty {
  position: relative;
  top: 0 !important;
  height: 145px;
  box-shadow: none;
  #trust-custom-badge-horizontal {
    position: absolute;
    top: 60px;
    &.ci-trustedshops {
      padding: 8px 0 2px;
    }
  }
}
@media screen and (min-width: 640px){
  #site-header.header-empty {
    #logo {
      margin: 0 20px;
    }
    #trust-custom-badge-horizontal {
      position: relative;
      top: 0;
      width: auto;
      float: right;
      margin: 10px 10px 0;
      border: none;
    }
  }
}
@media screen and (min-width: 1024px){
  #site-header.header-empty {
    height: 140px;
    #logo {
      flex: auto;
    }
    #trust-custom-badge-horizontal {
      margin: 20px 10px 0;
    }
  }
}
@media screen and (min-width: 1024px){
  #site-header.header-empty {
    #trust-custom-badge-horizontal {
      margin: 25px 10px 0;
    }
  }
}


// algolia search
#site-header {
  &[data-layer=search]{
    #algolia-search {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 36px;
      margin: 0;
      .search-algolia {
        margin: 0;
      }
      .search-algolia {
        .input-box {
          .clear-search-input {
            top: 0;
            right: 0;
            display: block;
            height: 30px;
            box-sizing: border-box;
            &:after {
              position: absolute;
              top: 1px;
              right: 1px;
              width: 24px;
              height: 29px;
              font-family: @icon;
              font-size: 16px;
              line-height: 33px;
              content: '\e901';
              color: @darkgrey;
              background: @white;
            }
          }
          input#search {
            border: none;
            border-bottom: 1px solid @darkgrey;
            .form-shadow;
            &:placeholder-shown {
              & ~ .clear-search-input {
                display: none;
              }
            }
          }
          button[type="submit"] {
            display: none;
          }
        }
      }
    }
    .search-suggests {
      display: block;
    }
  }
  .search-suggests {
    display: none;
    position: fixed;
    top: 36px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;
    .form-shadow;
    background-color: @white;
    & > div {
      display: grid;
      grid-template-columns: 50% 50%;
      .search-suggests-text {
        grid-column-start: 1;
        grid-column-end: 2;
      }
      .search-suggests-products {
        grid-column-start: 1;
        grid-column-end: 3;
      }
      .products-mobile {
        grid-column-start: 2;
        grid-column-end: 3;
      }
    }
    .search-suggests-text {
      box-sizing: border-box;
      .noresults {
        margin: 15px 0;
        padding: 0 0 15px;
        border-bottom: 1px solid @mediumgrey;
        .sans-serif-regular();
        font-size: 13px;
        line-height: 20px;
      }
      .search-suggests-text-title {
        margin: 20px 0 15px;
        .sans-serif-bold();
        font-size: 15px;
        line-height: 15px;
        text-transform: none;
      }
      .search-suggests-text-items {
        margin: 15px 0;
        padding: 0 0 15px;
        border-bottom: 1px solid @mediumgrey;
        .search-suggests-text-item {
          margin: 0 0 5px;
          .sans-serif-regular();
          font-size: 13px;
          line-height: 20px;
          mark {
            .sans-serif-bold();
            background-color: @yellow;
          }
          a {
            display: block;
            color: @darkgrey;
          }
          .update-search {
            cursor: pointer;
            &:before {
              display: block;
              float: right;
              font-family: @icon;
              font-size: 20px;
              content: '\e910';
              color: @mediumgrey;
            }
          }
        }
      }
    }
    .search-suggests-products {
      box-sizing: border-box;
      .search-suggests-products-title {
        margin: 20px 0 15px;
        padding-left: 0;
        .sans-serif-bold();
        font-size: 15px;
        line-height: 15px;
        text-transform: none;
      }
      .search-suggests-products-items {
        display: -ms-flex;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        .search-suggests-products-item {
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          box-sizing: border-box;
          border-bottom: 1px solid @mediumgrey;
          margin: 0 0 20px;
          padding: 0 0 20px;
          &:first-child, &:nth-child(2){
            display: none;
          }
          a {
            color: @darkgrey;
            text-decoration: none;
          }
          .search-suggests-products-item-image {
            display: block;
            width: 45%;
            max-width: 170px;
            margin: 0 10px 0 0;
            float: left;
          }
          .search-suggests-products-item-flags {
            display: block;
            margin: 0 0 10px;
            .flag {
              float: none;
              &:first-child {
                margin: 0 5px 0 0;
              }
              strong {
                font-size: 10px;
              }
            }
          }
          .search-suggests-products-item-name {
            font-size: 13px;
            line-height: 20px;
            display: block;
            margin: 0 0 10px;
            em {
              font-style: normal;
            }
          }
          .search-suggests-products-item-prices {
            span {
              display: inline-block;
              margin-right: 10px;
              .sans-serif-bold();
              font-size: 13px;
              line-height: 16px;
            }
            &.sale {
              span {
                &:first-child {
                  text-decoration: line-through;
                }
                &:nth-child(2){
                  color: @pink;
                }
              }
            }
          }
        }
      }
    }
    .search-suggests-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: 60px;
      background-color: #fff;
      border-top: 1px solid #ccc;
      text-align: center;
      .button {
        display: inline-block;
        margin: 10px auto 0;
        padding: 5px 40px;
      }
    }
    .products-mobile {
      li {
        margin: 20px 0 0;
        padding: 0 0 0 10px;
        a {
          position: relative;
          display: block;
          color: @darkgrey;
          text-decoration: none;
          text-align: center;
          .form-shadow;
          .image {
            display: block;
          }
          .search-suggests-products-item-flags {
            position: absolute;
            top: 0;
            left: 0;
            .flag {
              display: block;
              strong {
                font-size: 10px;
              }
            }
          }
          .search-suggests-products-item-name {
            display: block;
            margin: 0 0 10px;
            font-size: 13px;
            line-height: 20px;
            em {
              font-style: normal;
            }
          }
          .search-suggests-products-item-prices {
            padding: 0 0 10px 0;
            span {
              display: inline-block;
              margin-right: 10px;
              .sans-serif-bold();
              font-size: 13px;
              line-height: 16px;
            }
            &.sale {
              span {
                &:first-child {
                  text-decoration: line-through;
                }
                &:nth-child(2){
                  color: @pink;
                }
              }
            }
          }
        }
      }
    }
  }
}

// prices / currencies
body:not([data-currency='eur']) &.currency-eur,
body:not([data-currency='gbp']) &.currency-gbp,
body:not([data-currency='usd']) &.currency-usd,
body:not([data-currency='dkk']) &.currency-dkk,
body:not([data-currency='sek']) &.currency-sek,
body:not([data-currency='pln']) &.currency-pln,
body:not([data-currency='czk']) &.currency-czk,
body:not([data-currency='bgn']) &.currency-bgn,
body:not([data-currency='ron']) &.currency-ron,
body:not([data-currency='huf']) &.currency-huf {
  display: none;
}

@media screen and (min-width: 768px){
  #site-header {
    &[data-layer=search]{
      #algolia-search {
        position: absolute;
        top: 68px;
        left: 10px;
        right: 10px;
      }
    }
    .search-suggests {
      position: absolute;
      top: 35px;
      left: 0;
      right: 0;
      padding: 50px 20px 20px;
      height: calc(100vh - 139px);
      overflow: hidden;
      .search-suggests-text {
        position: absolute;
        top: 20px;
        left: 20px;
        bottom: 20px;
        right: 66%;
        padding-right: 20px;
        overflow-y: auto;
      }
      .search-suggests-products {
        position: absolute;
        top: 20px;
        left: 34%;
        bottom: 20px;
        right: 0;
        border-left: 1px solid @mediumgrey;
        padding-left: 20px;
        overflow-y: auto;
        .search-suggests-products-items {
          .search-suggests-products-item {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            &:first-child, &:nth-child(2){
              display: block;
            }
            a {
              display: -ms-flex;
              display: flex;
              -ms-flex-direction: column;
              flex-direction: column;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              align-content: space-between;
              height: 100%;
            }
            .search-suggests-products-item-image {
              width: 100%;
              flex: 0 0 170px;
            }
            .search-suggests-products-item-flags {
              position: relative;
              flex: 0 0 20px;
              margin: 0 0 5px;
              .flag {
                position: absolute;
                left: 0;
                bottom: 23px;
                margin: 0;
                &:first-child {
                  bottom: 0;
                  margin: 0;
                }
              }
            }
            .search-suggests-products-item-name {
              flex: 1 0 auto;
              margin: 0 10px 5px 0;
              align-self: flex-start;
            }
            .search-suggests-products-item-prices {
              flex: 0 0 32px;
            }
          }
        }
      }
      .search-suggests-footer {
        position: absolute;
        bottom: 10px;
        height: 50px;
        border-top: 1px solid #ccc;
      }
      & > div {
        display: block;
        grid-template-columns: none;
        .search-suggests-text {
          grid-column-start: unset;
          grid-column-end: unset;
        }
        .search-suggests-products {
          grid-column-start: unset;
          grid-column-end: unset;
        }
        .products-mobile {
          display: none;
        }
      }
    }
  }
}
@media screen and (min-width: 769px){
  #site-header {
    .search-suggests {
      left: 10px;
      right: 10px;
      height: calc(100vh - 149px);
    }
  }
}
@media screen and (min-width: 1024px){
  #site-header {
    #algolia-search {
      position: initial;
      margin-top: 20px;
      .search-suggests {
        top: 55px;
        height: calc(100vh - 101px);
        padding: 50px 30px 20px;
        .search-suggests-text {
          padding-right: 30px;
        }
        .search-suggests-products {
          padding-left: 30px;
          .search-suggests-products-items {
            .search-suggests-products-item {
              -ms-flex: 0 0 percentage((1/3));
              flex: 0 0 percentage((1/3));
            }
          }
        }
      }
    }

    &[data-layer=search] {
      #algolia-search {
        position: initial;
        margin-top: 20px;
        right: auto;
        left: auto;
        -ms-flex: 2 0 auto;
        flex: 2 0 auto;
        .search-algolia .input-box input#search {
          border: 1px solid @juwelogold;
          box-shadow: none;
        }
      }
    }
  }
}
@media screen and (min-width: 1280px){
  #site-header {
    #algolia-search {
      position: relative;
      right: auto;
      left: auto;
      margin: 0 60px 0 50px;
      .flex-item-expand();
      .search-suggests {
        top: 76px;
        height: calc(100vh - 125px);
      }
    }

    &[data-layer=search] {
      #algolia-search {
        margin: 36px 60px 0 50px;
      }
    }
  }
}


:root {
  --leftend: -300%;
}
@media screen and (min-width: 768px){
  :root {
    --leftend: -200%;
  }
}
@media screen and (min-width: 1200px){
  :root {
    --leftend: -100%;
  }
}

@keyframes greetings {
  from { left: calc(100% - 50px); }
  to { left: var(--leftend); }
}
#header-banderole.greetings {
  .max-width {
    display: block;
    height: 30px;
    text-align: left;
    overflow: hidden;
  }
  .close {
    z-index: 3;
    &:after {
      color: @white;
    }
  }
  p {
    position: relative;
    margin: 0;
    line-height: 28px;
    span {
      position: absolute;
      top: 0;
      left: auto;
      min-width: 100%;
      display: block;
      opacity: 0;
      transition: opacity 3s;
      color: @white;
      white-space: nowrap;
      animation-name: greetings;
      animation-duration: 30s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }
  &[data-count="1"] .max-width {
    background-color: #6e1e5a;
    .close {
      background-color: #6e1e5a;
    }
  }
  &[data-count="2"] .max-width {
    background-color: #69beaa;
    .close {
      background-color: #69beaa;
    }
  }
  &[data-count="3"] .max-width {
    background-color: #EB5F9B;
    .close {
      background-color: #EB5F9B;
    }
  }
  &[data-count="4"] .max-width {
    background-color: #006e96;
    .close {
      background-color: #006e96;
    }
  }
  &[data-count="5"] .max-width {
    background-color: #a5be19;
    .close {
      background-color: #a5be19;
    }
  }
  &[data-count="1"] p span:first-child,
  &[data-count="2"] p span:nth-child(2),
  &[data-count="3"] p span:nth-child(3),
  &[data-count="4"] p span:nth-child(4),
  &[data-count="5"] p span:nth-child(5) {
    opacity: 1;
    transition: opacity 1s;
  }
}

.header-language-switch {
  position: relative;
  background-color: #ffeab9;
  max-height: 90px;
  & > div {
    position: relative;
    display: none;
    grid-template-columns: auto 10px 30px 30px;
    grid-template-rows: auto 30px;
    box-sizing: border-box;
    max-width: 1200px;
    margin: 0 auto;
    padding: 5px 10px;
    .sans-serif-bold();
    p {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 4;
      position: relative;
      display: inline-block;
      margin: 0;
      align-self: center;
    }
    .toggle-stores-nav {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: 2;
      position: relative;
      display: inline-block;
      height: 30px;
      padding: 0 30px 0 0;
      border-radius: 5px;
      background-color: @white;
      line-height: 30px;
      text-align: left;
      align-self: center;
      cursor: pointer;
      img {
        width: 20px;
        height: 15px;
        margin: 7px 10px 0 10px;
        float: left;
      }
      &:after {
        position: absolute;
        right: 10px;
        font-family: @icon;
        font-size: 20px;
        line-height: 30px;
        content: '\f107';
      }
      &.stores-nav-open:after {
        content: '\f106';
      }
    }
    a.button {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 3;
      grid-column-end: 4;
      width: 60px;
      height: 30px;
      border-radius: 5px;
      font-size: 0;
      line-height: 19px;
      align-self: center;
      &:after {
        font-family: @icon;
        font-size: 20px;
        content: '\f061';
      }
    }
    .close {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 4;
      grid-column-end: 5;
      position: relative;
      right: 0;
      display: inline-block;
      height: 30px;
      text-align: center;
      align-self: flex-start;
      cursor: pointer;
      &:after {
        position: relative;
        float: none;
        font-family: @icon;
        font-size: 20px;
        line-height: 30px;
        content: '\e901';
        background-color: transparent;
        color: @darkgrey;
      }
    }
  }
  &.de div.de,
  &.en div.en,
  &.es div.es,
  &.fr div.fr,
  &.it div.it,
  &.nl div.nl {
    display: grid;
  }
  .stores-nav {
    position: absolute;
    top: calc(100% - 5px);
    left: 10px;
    z-index: 40;
    width: 150px;
    padding: 10px;
    background-color: @white;
    border-radius: 5px;
    .popup-shadow;
    display: none;
    &.stores-nav-open {
      display: block;
    }
    li {
      margin: 0;
      line-height: 30px;
      border-top: 1px solid @mediumgrey;
      &:first-child {
        border-top: none;
      }
      img {
        width: 20px;
        height: 15px;
        margin: 7px 5px 0 0;
        float: left;
      }
    }
  }
}
@media screen and (min-width: 640px){
  .header-language-switch {
    & > div {
      grid-template-columns: auto 170px 60px 40px;
      grid-template-rows: 30px;
      grid-column-gap: 10px;
      p {
        grid-column-start: 1;
        grid-column-end: 2;
        text-align: right;
      }
      .toggle-stores-nav {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 3;
      }
      a.button {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 3;
        grid-column-end: 4;
      }
      .close {
        grid-column-start: 4;
        grid-column-end: 5;
      }
    }
    .stores-nav {
      left: auto;
      right: 130px;
    }
  }
}
@media screen and (min-width: 769px){
  .header-language-switch {
    & > div {
      padding: 5px 20px;
    }
    .stores-nav {
      right: 140px;
    }
  }
}
@media screen and (min-width: 1200px){
  .header-language-switch {
    .stores-nav {
      right: calc((100% - 1200px)/2 + 140px);
    }
  }
}