footer {
  font-size: 13px;
  a {
    color: @darkgrey;
  }
  h3, p {
    margin: 0;
  }
  .block {
    padding: 0;
  }
  .clear {
    &.decorative {
      margin-bottom: 20px;
      &:before {
        left: 5px;
      }
      &:after {
        right: 5px;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  footer {
    .clear {
      &.decorative {
        &:before {
          left: 10px;
        }
        &:after {
          right: 10px;
        }
      }
    }
  }
}

.footer-section {
  box-sizing: border-box;
  padding: 20px 0;
  &.price-footer-info {
    padding: 20px 0 0;
    color: @mediumgrey;
  }
  &.section-service {
    background-color: @white;
  }
  &.section-categories {
    background-color: @lightgreyfooter;
  }
  &.section-company {
    background-color: @mediumgreyfooter;
  }
}

.footer-icon {
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto 10px;
  overflow: hidden;
  background-image: url('../images/footer-icons-juwelo-plus.png');
  background-size: 900%;
  &-gem { background-position: 0 0; }
  &-phone { background-position: -100% 0; }
  &-trusted-shops { background-position: -400% 0; }
  &-prices { background-position: -500% 0; }
  &-trustpilot { background-position: -600% 0; }
  &-email { background-position: -800% 0; }
}
.x-adaptive-css .footer-icon {
  background-image: none;
}
.social-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: -4px 10px 14px 0; // -4px optical horizontal alignment
  border-radius: 40px;
  background-image: url('../images/footer-icons-social-media.png');
  background-size: 500%;
  &:before {
    display: none;
  }
  &.facebook {
    background-position: 0 0;
  }
  &.twitter {
    background-position: -100% 0;
  }
  &.pinterest {
    background-position: -200% 0;
  }
  &.youtube {
    background-position: -300% 0;
  }
  &.instagram {
    background-position: -400% 0;
  }
}
.x-adaptive-css .social-icon {
  background-image: none;
}

.de .payment-icon {
  background-image: url('../images/footer-icons-payments-de.png');
}
.en .payment-icon {
  background-image: url('../images/footer-icons-payments-en.png');
}
.es .payment-icon {
  background-image: url('../images/footer-icons-payments-es.png');
}
.fr .payment-icon {
  background-image: url('../images/footer-icons-payments-fr.png');
}
.it .payment-icon {
  background-image: url('../images/footer-icons-payments-it.png');
}
.nl .payment-icon {
  background-image: url('../images/footer-icons-payments-nl.png');
}
.de .x-adaptive-css .payment-icon ,
.en .x-adaptive-css .payment-icon,
.es .x-adaptive-css .payment-icon,
.fr .x-adaptive-css .payment-icon,
.it .x-adaptive-css .payment-icon,
.nl .x-adaptive-css .payment-icon {
  background-image: none;
}
.payment-icon {
  display: inline-block;
  width: 69px;
  height: 40px;
  margin: 0 5px 5px 0;
  border-radius: 5px;
  background-size: 1100%;
  font-size: 0;
  line-height: 0;
  &.visa {
    background-position: 0 0 ;
  }
  &.mastercard {
    background-position: -100% 0 ;
  }
  &.amex {
    background-position: -200% 0 ;
  }
  &.diners {
    background-position: -300% 0 ;
  }
  &.discover {
    background-position: -400% 0 ;
  }
  &.paypal {
    background-position: -500% 0 ;
  }
  &.ideal {
    background-position: -600% 0 ;
  }
  &.rechnung-en,
  &.rechnung-es,
  &.rechnung-fr,
  &.rechnung-it,
  &.rechnung-nl,
  &.rechnung {
    background-position: -700% 0 ;
  }
  &.vorkasse-en,
  &.vorkasse-es,
  &.vorkasse-fr,
  &.vorkasse-it,
  &.vorkasse-nl,
  &.vorkasse {
    background-position: -800% 0 ;
  }
  &.nachnahme-en,
  &.nachnahme-es,
  &.nachnahme-fr,
  &.nachnahme-it,
  &.nachnahme-nl,
  &.nachnahme {
    background-position: -900% 0 ;
  }
  &.bancontact {
    background-position: -1000% 0 ;
  }
}
.shipping-icon {
  display: inline-block;
  width: 69px;
  height: 40px;
  margin: 0 5px 5px 0;
  border-radius: 5px;
  background-image: url('../images/footer-icons-shipping.png');
  background-size: 1100%;
  font-size: 0;
  line-height: 0;
  &.dhl {
    background-position: 0 0 ;
  }
  &.gogreen {
    background-position: -100% 0;
  }
  &.post-at {
    background-position: -200% 0;
  }
  &.colissimo {
    background-position: -300% 0;
  }
  &.celeritas {
    background-position: -400% 0;
  }
  &.bpost {
    background-position: -500% 0;
  }
  &.brt {
    background-position: -600% 0;
  }
  &.sda {
    background-position: -700% 0;
  }
  &.gls {
    background-position: -800% 0;
  }
  &.seur {
    background-position: -900% 0;
  }
  &.postnl {
    background-position: -1000% 0;
  }
}
.x-adaptive-css .shipping-icon {
  background-image: none;
}
.de .app-icon {
  background-image: url('../images/footer-icons-apps-de.png');
}
.en .app-icon {
  background-image: url('../images/footer-icons-apps-en.png');
}
.es .app-icon {
  background-image: url('../images/footer-icons-apps-es.png');
}
.fr .app-icon {
  background-image: url('../images/footer-icons-apps-fr.png');
}
.it .app-icon {
  background-image: url('../images/footer-icons-apps-it.png');
}
.nl .app-icon {
  background-image: url('../images/footer-icons-apps-nl.png');
}
.app-icon {
  display: inline-block;
  width: 101px;
  height: 30px;
  margin: 0 10px 10px 0;
  border-radius: 3px;
  background-size: 200%;
  font-size: 0;
  line-height: 0;
  &.google {
    background-position: 0 0 ;
  }
  &.apple {
    background-position: -100% 0;
  }
}
.de .x-adaptive-css .app-icon,
.en .x-adaptive-css .app-icon,
.es .x-adaptive-css .app-icon,
.fr .x-adaptive-css .app-icon,
.it .x-adaptive-css .app-icon,
.nl .x-adaptive-css .app-icon {
  background-image: none;
}
.store-flag {
  display: block;
  width: 30px;
  height: 18px;
  margin: 0 2px;
  border-radius: 2px;
  background-image: url('../images/footer-icons-flags.png');
  background-size: 700%;
  &.de {
    background-position: 0 0;
  }
  &.fr {
    background-position: -100% 0;
  }
  &.nl {
    background-position: -200% 0;
  }
  &.es {
    background-position: -300% 0;
  }
  &.uk {
    background-position: -400% 0;
  }
  &.it {
    background-position: -500% 0;
  }
  &.us {
    background-position: -600% 0;
  }
}
.x-adaptive-css .store-flag {
  background-image: none;
}

.section-service {
  padding-top: 10px;
  padding-bottom: 10px;
  .col-2-set {
    &:before {
      display: block;
      margin: 0 5px 10px;
      background-color: @pink;
      font-size: 12px;
      line-height: 24px;
      text-align: center;
      color: @white;
    }
    .col {
      width: 100%;
    }
    .col-lists {
      .footer-icon {
        margin-bottom: -40px;
      }
      ul {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        &:before {
          display: block;
          width: 40px;
          height: 40px;
          margin: 0 auto 10px;
          overflow: hidden;
          background-image: url('../images/footer-icons-juwelo-plus.png');
          background-size: 900%;
          content: ' ';
        }
        li {
          line-height: 15px;
          margin: 15px 0 0;
        }
      }
      .service {
        box-sizing: border-box;
        border-right: 1px solid @mediumgrey;
        ul {
          &:before {
            background-position: -200% 0;
          }
        }
      }
      .juwelo {
        box-sizing: border-box;
        ul {
          &:before {
            background-position: -300% 0;
          }
        }
      }
    }
    .col-table {
      a {
        text-decoration: underline;
      }
      &:after {
        display: block;
        margin: 10px 0;
        background-color: @pink;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        color: @white;
      }
    }
  }
  article {
    width: 50%;
    text-align: center;
    &.service {
      float: left;
    }
    &.juwelo {
      float: left;
    }
  }
  .table {
    vertical-align: center;
    .td {
      position: relative;
      box-sizing: border-box;
      height: 167px;
      padding: 20px 10px;
      &.jewels {
        border-right: 1px solid @mediumgrey;
        border-bottom: 1px solid @mediumgrey;
        &:after {
          position: absolute;
          left: 100%;
          top: 100%;
          display: block;
          width: 20px;
          height: 20px;
          margin: -10px 0 0 -10px;
          /*
          font-size: 20px;
          font-family: monospace;
          content: '♦';
          color: @pink !important;
          */
          background-color: @white;
          background-image: url('../images/raute.png');
          background-size: 35%;
          background-repeat: no-repeat;
          background-position: center;
          content: ' ';
        }
      }
      &.prices {
        border-bottom: 1px solid @mediumgrey;
      }
      &.hotline, &.email {
        border-right: 1px solid @mediumgrey;
      }
      h3 {
        .sans-serif-bold();
        margin: 0 0 10px;
      }
    }
  }
  &.de {
    .col-table {
      &:after {
        content: 'Ihr Experte für zertifizierten Edelsteinschmuck.';
      }
    }
  }
  &.en {
    .col-table {
      &:after {
        content: 'Your online gemstone jewellery specialist for certificated gemstone jewellery';
      }
    }
  }
  &.es {
    .col-table {
      &:after {
        content: 'Su experto de joyas con piedras preciosas certificadas';
      }
    }
  }
  &.fr {
    .col-table {
      &:after {
        content: 'Votre expert en gemmes précieuses et fines certifiées';
      }
    }
  }
  &.it {
    .col-table {
      &:after {
        content: 'Il vostro esperto di gemme preziose certificate';
      }
    }
  }
  &.nl {
    .col-table {
      &:after {
        content: 'Uw online edelsteen sieradenspecialist voor echte echte edelsteen sieraden met certificaat';
      }
    }
  }
}
.x-adaptive-css .section-service {
  .col-2-set {
    .col-lists {
      ul {
        &:before {
          background-image: none;
        }
      }
    }
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 1.3), screen and (-o-min-device-pixel-ratio: 13/10), screen and (min-resolution: 120dpi) {
  .section-service {
    .table {
      .td {
        &.jewels {
          &:after {
            font-size: 17px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .section-service {
    padding-bottom: 0;
    .col-2-set {
      &:after {
        display: block;
        margin: 0 5px;
        background-color: @pink;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        color: @white;
        content: 'Ihr Experte für zertifizierten Edelsteinschmuck.';
      }
      .col {
        width: 50%;
      }
      .col-table {
        float: right;
        padding-left: 0;
        &:after {
          content: '' !important;
        }
      }
      .col-lists {
        ul {
          padding: 20px 0;
          height: 334px;
          border-left: 1px solid @mediumgrey;
          text-align: center;
          vertical-align: middle;
        }
        .service {
          border-right: none;
        }
      }
    }
    .table {
      .td {
        &.jewels {
          border-left: 1px solid @mediumgrey;
        }
        &.hotline, &.email {
          border-left: 1px solid @mediumgrey;
          &:after {
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 20px;
            height: 20px;
            margin: -10px 0 0 -10px;
            /*
            font-size: 20px;
            font-family: monospace;
            content: '♦';
            color: @pink !important;*/
            background-color: @white;
            background-image: url('../images/raute.png');
            background-size: 35%;
            background-repeat: no-repeat;
            background-position: center;
            content: ' ';
          }
        }
        &.prices {
          border-right: 1px solid @mediumgrey;
          border-bottom: 1px solid @mediumgrey;
          &:after {
            position: absolute;
            left: 100%;
            top: 100%;
            display: block;
            width: 20px;
            height: 20px;
            margin: -10px 0 0 -9px;
            /*
            font-size: 20px;
            font-family: monospace;
            content: '♦';
            color: @pink !important;*/
            background-color: @white;
            background-image: url('../images/raute.png');
            background-size: 35%;
            background-repeat: no-repeat;
            background-position: center;
            content: ' ';
          }
        }
        &.trust {
          border-right: 1px solid @mediumgrey;
        }
      }
    }
    &.de {
      .col-2-set {
        &:after {
          content: 'Ihr Experte für zertifizierten Edelsteinschmuck.';
        }
      }
    }
    &.en {
      .col-2-set {
        &:after {
          content: 'Your online gemstone jewellery specialist for certificated gemstone jewellery';
        }
      }}
    &.es {
      .col-2-set {
        &:after {
          content: 'Su experto de joyas con piedras preciosas certificadas';
        }
      }}
    &.fr {
      .col-2-set {
        &:after {
          content: 'Votre expert en gemmes précieuses et fines certifiées';
        }
      }}
    &.it {
      .col-2-set {
        &:after {
          content: 'Il vostro esperto di gemme preziose certificate';
        }
      }
    }
    &.nl {
      .col-2-set {
        &:after {
          content: 'Uw online edelsteen sieradenspecialist voor echte echte edelsteen sieraden met certificaat';
        }
      }
    }
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 1.3), screen and (-o-min-device-pixel-ratio: 13/10), screen and (min-resolution: 120dpi) {
  .section-service {
    .table {
      .td {
        &.hotline, &.email {
          &:after {
            left: -1px;
            font-size: 17px;
          }
        }
        &.prices {
          &:after {
            font-size: 17px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .section-service {
    padding-top: 20px;
    padding-bottom: 20px;
    .col-2-set {
      &:after {
        margin: 10px 10px 0;
      }
    }
  }
}

.section-categories {
  .col-4-set {
    .col {
      width: 50%;
      margin-bottom: 20px;
    }
    .col-payments,
    .col-shipping,
    .col-app,
    .col-social {
      width: 100%;
      font-size: 0;
      line-height: 0;
    }
    .col-shipping {
      img {
        height: 25px;
        width: auto;
      }
    }
    .col-app {
      img {
        width: auto;
        height: 25px;
      }
    }
    .col-seals {
      width: 100%;
    }
    .col-social {
      .block-apps {
        margin-top: 30px;
      }
    }
  }
  #trust-custom-badge-footer {
    position: relative;
    z-index: 2;
    display: block;
    width: 91px;
    border-radius: 8px;
    box-sizing: content-box;
    padding: 8px 0;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    border-color: #ffffff;
    float: left;
    box-shadow: none;
    cursor: pointer;
    &:hover {
      border-color: #ffdc0f;
      text-decoration: none;
    }
    .image-container {
      position: relative;
      display: block;
      width: 50px;
      height: 50px;
      margin: 0 auto;
      img {
        width: 50px;
        height: 50px;
      }
    }
    .kaeuferschutz {
      display: block;
      margin: 5px 7px;
      padding: 0 0 5px;
      font-size: 13px;
      line-height: 15px;
      border-bottom: 1px solid rgb(229, 229, 229);
    }
    .rating-stars {
      margin: 0;
      padding: 5px 0;
      .star {
        font-size: 13px;
        line-height: 20px;
      }
    }
    .rating-note {
      display: block;
      margin: 0;
      font-size: 13px;
      line-height: 24px;
    }
    .rating-score {
      margin: 0;
      padding: 0;
      line-height: 10px;
      &-value {
        font-size: 14px;
        font-weight: bold;
      }
      &-max {
        font-size: 12px;
      }
    }
  }
  #topshop2022 {
    float: left;
    width: 93px;
    margin-left: 10px;
  }
}
@media screen and (min-width: 480px) {
  .section-categories {
    .col-4-set {
      .col-payments,
      .col-shipping,
      .col-app,
      .col-seals,
      .col-social {
        width: 50%;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .clear.max-768 {
    display: none;
  }
  .section-categories {
    .col-4-set {
      .col {
        width: 25%;
      }
      .col-payments,
      .col-shipping,
      .col-app,
      .col-social {
        width: 25%;
      }
    }
    #trust-custom-badge-footer {
      width: 81px;
      .kaeuferschutz {
        margin: 2px 7px;
        padding: 0 0 2px;
        font-size: 11px;
        line-height: 13px;
      }
      .rating-stars {
        padding: 2px 0;
      }
      .rating-note {
        font-size: 11px;
        line-height: 20px;
      }
      .rating-score {
        line-height: 10px;
        &-value {
          font-size: 12px;
        }
        &-max {
          font-size: 10px;
        }
      }
    }
    #topshop2022 {
      width: 73px;
    }
  }
}
@media screen and (min-width: 1024px){
  .section-categories {
    #trust-custom-badge-footer {
    width: 91px;
    .kaeuferschutz {
      margin: 5px 7px;
      padding: 0 0 5px;
      font-size: 13px;
      line-height: 15px;
    }
    .rating-stars {
      padding: 5px 0;
    }
    .rating-note {
      font-size: 13px;
      line-height: 24px;
    }
    .rating-score {
      line-height: 10px;
      &-value {
        font-size: 14px;
      }
      &-max {
        font-size: 12px;
      }
    }
  }
    #topshop2022 {
      width: 93px;
    }
  }
}


.section-company {
  .company-nav {
    box-sizing: border-box;
    text-align: center;
    line-height: 0;
    font-size: 0;
    li {
      display: inline-block;
      color: @darkgrey;
      &:before {
        font-size: 13px;
        line-height: 15px;
        content: '|';
      }
      &:first-child {
        &:before {
          content: '';
        }
      }
      a {
        padding: 0 5px;
        font-size: 13px;
        line-height: 15px;
        color: @darkgrey;
      }
    }
  }
  .stores-nav {
    box-sizing: border-box;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    p {
      margin: 0;
    }
    a {
      display: inline-block;
    }
    .label {
      display: block;
      margin: 0 0 10px;
    }
  }
  .copyright {
    box-sizing: border-box;
    padding: 15px 0;
    text-align: center;
    p {
      margin: 0;
      font-size: 13px;
      line-height: 15px;
    }
  }
}
@media screen and (min-width: 480px) {
  .section-company {
    .company-nav {
      li {
        a {
          padding: 0 10px;
        }
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .section-company {
    .company-nav {
      width: 60%;
      padding-left: 10px;
      float: left;
      text-align: left;
    }
    .stores-nav {
      margin-right: 20px;
      float: right;
      text-align: left;
    }
    .copyright {
      width: 60%;
      padding: 20px 0 0 20px;
      float: left;
      text-align: left;
    }
  }
}
@media screen and (min-width: 1025px) {
  .section-company {
    .stores-nav {
      width: 25%;
      margin-right: 0;
      padding: 0 10px;
      float: right;
      text-align: left;
      .store-flag {
        margin: 0 5px 0 0;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .section-company {
    .stores-nav {
      .store-flag {
        margin: 0 8px 0 0;
      }
    }
  }
}

// footer empty
.footer-empty {}