.section-newsletter {

  // common styles
  .max-width {
    position: relative;
    .image-box {
    }
    .content-box {
      padding: 20px 10px;
      h2 {
        text-align: left;
      }
      .input-box-email {
        margin: 0 0 10px;
        input[type=email] {
          height: 38px;
          border: 1px solid @darkgrey;
          padding: 6px 4px;
          font-size: 16px;
        }
      }
      .button {
        margin: 0 0 10px;
      }
      .frc-captcha {
        background: none;
        border: none;
        min-width: unset;
        max-width: unset;
        margin: 0;
        padding: 0;
        .frc-container {
          min-height: 42px;
          .frc-icon {
            margin: 0 5px 0 0;
          }
          .frc-content {
            margin: 0 0 0 5px;
            .frc-text {
              display: none;
            }
            .frc-buton {}
          }
        }
        .frc-banner {
          display: none;
        }
      }
    }
    .data-privacy-text {
      font-size: 11px;
      line-height: 15px;
      text-align: left;
      a {
        text-decoration: underline;
      }
    }
  }
  &.pink-scheme {
    h2 {
      color: @white;
    }
    button {
      background-color: @black;
      border-color: @black;
      color: @white;
    }
  }
  &.black-scheme {
    h2 {
      color: @white;
    }
    .frc-captcha {
      .frc-container {
        .frc-icon {
          fill: @white;
          stroke: @white;
        }
        .frc-content {
          .frc-buton {}
        }
      }
    }
  }

  // widget-mode
  &:not(.popup-mode){
    position: relative;
    padding: 0 10px;
    .max-width {
      h2 {
        display: none;
      }
      .image-box {
        .image-popup {
          display: none;
        }
      }
      .content-box {
        h2 {
          margin: 0 0 10px;
        }
      }
      .data-privacy-text {
        margin: 5px 0 0;
      }
      #close_nl_popup {
        display: none;
      }
    }
    &.light-scheme {
      .content-box {
        background-color: lighten(#beb4aa, 10%);
      }
    }
    &.pink-scheme {
      .content-box {
        background-color: @pink;
      }
    }
    &.black-scheme {
      .content-box {
        background-color: @black;
      }
    }
  }

  // popup-mode
  &.popup-mode {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    padding: 0 10px;
    background-color: rgba(255,255,255,.9);
    .max-width {
      max-width: 560px;
      margin: 15px auto 0;
      border-radius: 5px;
      overflow: hidden;
      .popup-shadow();
      .image-box {
        .image-widget {
          display: none;
        }
      }
      .data-privacy-text {
        padding: 0 10px;
      }
      #close_nl_popup {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 40px;
        height: 40px;
        border: none;
        font-size: 0;
        line-height: 0;
        text-align: center;
        cursor: pointer;
        &:after {
          font-family: @icon;
          font-size: 20px;
          line-height: 40px;
          content: '\e901';
        }
      }
    }
    &.light-scheme {
      .max-width {
        background-color: #e7e2de;
      }
      #close_nl_popup {
        &:after {
          color: @darkgrey;
          text-shadow: 0px 0px 2px @white;
        }
      }
    }
    &.pink-scheme {
      .max-width {
        background-color: @pink;
        .data-privacy-text {
          color: @white;
          a {
            color: @white;
          }
        }
      }
      #close_nl_popup {
        &:after {
          color: @white;
          text-shadow: 0px 0px 2px @black;
        }
      }
    }
    &.black-scheme {
      .max-width {
        background-color: @black;
        .data-privacy-text {
          color: @white;
          a {
            color: @white;
          }
        }
      }
      #close_nl_popup {
        &:after {
          color: @white;
          text-shadow: 0px 0px 2px @pink;
        }
      }
    }
  }

}
@media screen and (min-width: 580px) {
  .section-newsletter {

    // widget-mode
    &:not(.popup-mode){
      .max-width {
        .content-box {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 10px;
          h2 {
            grid-column-start: 1;
            grid-column-end: 3;
          }
          .input-box-email {
            grid-column-start: 1;
            grid-column-end: 2;
          }
          button {
            grid-column-start: 2;
            grid-column-end: 3;
          }
          .frc-captcha {
            grid-column-start: 1;
            grid-column-end: 3;
          }
        }
      }
    }

  }
}
@media screen and (min-width: 680px) {
  .section-newsletter {}
}
@media screen and (min-width: 768px) {
  .section-newsletter {

    // widget-mode
    &:not(.popup-mode){
      .max-width {
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-width: 1160px;
        h2 {
          display: block;
        }
        .image-box {
          grid-column-start: 1;
          grid-column-end: 2;
          .image-widget {
            display: none;
          }
          .image-popup {
            display: block;
          }
        }
        .content-box {
          grid-column-start: 2;
          grid-column-end: 3;
          display: block;
        }
        .data-privacy-text {
          grid-column-start: 1;
          grid-column-end: 3;
          text-align: center;
        }
      }
    }

    // popup-mode
    &.popup-mode {
      display: flex;
      align-items: center;
      .max-width {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1.4fr .6fr;
        max-width: 1000px;
        margin: 0 auto;
        .image-box {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 1;
          grid-row-end: 3;
        }
        .content-box {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 2;
          padding: 9% 20px;
        }
        .data-privacy-text {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 2;
          grid-row-end: 3;
          padding: 9% 20px;
        }
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .section-newsletter {

    // widget-mode
    &:not(.popup-mode){
      padding: 0 20px;
      .max-width {
        .content-box {
          padding: 20px;
        }
      }
    }

  }
}
@media screen and (min-width: 1024px) {
  .section-newsletter {

    // widget-mode
    &:not(.popup-mode){
      .max-width {
        .image-box {
          .image-widget {
            display: block;
          }
          .image-popup {
            display: none;
          }
        }
        .content-box {
          padding: 10px 20px;
        }
      }
    }

  }
}
@media screen and (min-width: 1110px) {
  .section-newsletter {

    // widget-mode
    &:not(.popup-mode){
      .max-width {
        .content-box {
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 10px;
          display: grid;
          padding: 20px;
          h2 {
            grid-column-start: 1;
            grid-column-end: 3;
          }
          .input-box-email {
            grid-column-start: 1;
            grid-column-end: 2;
          }
          button {
            grid-column-start: 2;
            grid-column-end: 3;
          }
          .frc-captcha {
            grid-column-start: 1;
            grid-column-end: 3;
          }
        }
      }
    }

  }
}